import issetFiled from "./issetFiled";

/**
 |--------------------------------------------------
 | Headers
 |--------------------------------------------------
 */
export default function headers() {
    const token_type = issetFiled("token_type")
        ? JSON.parse(localStorage.getItem("token_type"))
        : null;
    const access_token = issetFiled("access_token")
        ? JSON.parse(localStorage.getItem("access_token"))
        : null;
    const token = token_type + " " + access_token;
    // const token = token_type + " " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNDc4NGRmNDdjOWVjNzljZTVmYWViMGU3NjIzZWI0NTEzZjU4MTM5NWUwMWQyOTZkOTRmMjI4NDU4ZGQ5ZTMyYmQ1MDZiZDdmZTQ1ZDk2YTgiLCJpYXQiOjE2NjYyMzQ2NjcsIm5iZiI6MTY2NjIzNDY2NywiZXhwIjoxNjY2NDA3NDY3LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.fROv24wNDTO1wepCJUoE6fFANeT5gVJjcwpmz3bpX6cVQndTI0nB5VZ1Z9zTG5WDUJYRujm2kzc43K4dahRulu2csYetdzvc31jeHWmpRqV724ZrPfKwoluIf_4uEHfXbitUw6SIEzwrj--2fRYQpb_nBdUblBRKHkNTr7y9BKzrWNTJQafRwJGu_CW_bgUIakrsBuBe4AFV8K1-bjcHCz0FfUbT66kKvc78zkx4NBxBItIzEfpiE9VTkL92ocIRzC81qZY3Lu6_4MfVhGDEx_x312hl4Xs3tRltybdJIzDt0aU2dSMAupebUun9BEphXmHVwmfDU4rex3XL-m14wuAzuUQoNbqttAQSOZ2emCvoTZlTMH1RT2XQFTgKsK0jgP6P0kIrLIFW014xU-4p-r8RYvReTR-LWAhbQGtGDdemW8oR6NtonLY8_aA_oHGjrdWf1uPMsFkxLq_YpUD4dBDzLV4EYXY70MCUZd11PG1cNsCbDITmgh9jdiIuoIKAeyYisrNDUABpDGqlAi6_fRjEC-Ue2Xe9v0kg7ZMKd66f0jUEKpxYWq97WqkGB-lnWQcXiayzY_UaWIsfObXX6UvMV7jU8NqrrJQcOSwpGEpDOY_tpd0YYyUHhLqpE4HOot2RK7s20Vtko_xy67Zrh9eV4YbutjUqJR5evrfXV1I";
    return {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
    };
}
